import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import Swiper from 'react-id-swiper';
import 'swiper/css/swiper.css';


import media from '../common/mediaQuery'
import { Title, Type } from '../common/type'
import { theme } from '../common/global'
import VideoPlayer from './videoPlayer';


export const FullProject = ({ title, copy, client, year, category, tags, gallery, url }) => {
  const [isVisible, setVisible] = useState(false);

  const handleToggle = (value) => {
    setVisible(value);
  };

  const params = {
    // Provide Swiper class as props
    Swiper,
    effect: 'fade',
    centeredSlides: true,
    loopFillGroupWithBlank: false,
    shortSwipes: false,
    simulateTouchMove: false,
    threshold: 3,
    watchOverflow: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
      type: 'bullets',
    },
  }

  // Sort and display the different slice options
  const PostSlices = ({ slices }) => {
    return slices.map((slice) => {
      const res = (() => {
        switch(slice.type) {
          case 'image_gallery': return (
            <>
              <Swiper {...params}>
                {slice.fields.map((image) => (
                  <picture>
                    {image.is_video === true ?
                      <VideoPlayer isCarouselItem videoStill={image.video_still.url} videoLink={image.slide_video_link} />
                    :
                      <>
                        <source media="(min-width: 768px)" srcSet={image.desktop_image.url} />
                        <source srcSet={image.mobile_image.url} />
                        <img alt="" src={image.mobile_image.url} />
                        <div className="swiper-button-prev"></div>
                        <div className="swiper-button-next"></div>
                      </>
                    }
                  </picture>
                ))}
              </Swiper>
              <div className="swiper-pagination desktop-only"></div>
            </>
          )
        }
      })();
      return res;
    })
  }

  const GalleryPost = ({ gallery }) => {
    return (
      <PostSlices slices={ gallery } />
    );
  }

  return ( 
    <ProjectWrapper className="project-wrapper" open={isVisible}>
      <Intro onClick={() => handleToggle(!isVisible)}>
        <Item>
          <Title as="p">{ year }</Title>
        </Item>
        <Item>
          <Title as="p">{ client }</Title>
        </Item>
        <Item>
          <Title as="p">{ title }</Title>
        </Item>
      </Intro>
      <Content>
        <Meta>
          <Block>
            {/* <Link to={`/project/${url}`}> */}
              <Type bold>{ title }</Type>
            {/* </Link> */}
          </Block>
          {copy &&
            <Block mobileHidden>
              <Type>{ copy }</Type>
            </Block>
          }
          {client &&
            <Block mobileHidden>
              <Type>{ client }</Type>
              <Type>{ year }</Type>
            </Block>
          }
          {category && 
            <Block>
              <Type>{ category }</Type>
              {tags.map((tag) => (
                <Type capitalized>+ { tag }</Type>
              ))}
            </Block>
          }
        </Meta>
        <ImageContainer>
          <Close onClick={() => handleToggle(!isVisible)}>
            <svg viewBox="0 0 55.71 55.71">
              <polygon className="st0" points="55.71,0.71 55.01,0 27.86,27.15 0.71,0 0,0.71 27.15,27.86 0,55.01 0.71,55.71 27.86,28.56 
              55.01,55.71 55.71,55.01 28.56,27.86 "/>
            </svg>
          </Close>
          <GalleryPost gallery={gallery} />
        </ImageContainer>
      </Content>
    </ProjectWrapper>
  );
}

 
export default FullProject;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 100%;
  max-height: 0px;
  margin-top: 0;
  opacity: 0;
  overflow: hidden;
  padding: 0;
  position: relative;
  transition: all 0 ease-in-out;
  width: 100%;
`;

const ProjectWrapper = styled.li`
  display: flex;
  flex-wrap: wrap;
  margin-top: 0;
  opacity: 1;
  transition: opacity .2s ease-in-out;
  width: 100%;
  z-index: 100;

  ${media.tablet`
    padding: 0;
  `}

  &:first-of-type {
    margin-top: 10px;
  }

  &.inactive {
    opacity: .6;
  }

  .swiper-button-prev {
    cursor: url('../../elkin-arrow-left.png'), auto;
    height: 80%;
    left: 0;
    position: absolute;
    pointer-events: none;
    top: 20px;
    width: 25%;
    z-index: 100;

    &:after {
      display: none;
    }
  }

  .swiper-button-next {
    cursor: url('../../elkin-arrow-right.png'), auto;
    height: 80%;
    position: absolute;
    pointer-events: none;
    right: 0;
    top: 20px;
    width: 25%;
    z-index: 100;

    &:after {
      display: none;
    }
  }

  ${props => props.open && css`
    opacity: 1;

    ${Content} {
      max-height: 10000px;
      margin: 10px 0 0;
      opacity: 1;

      ${media.tablet`
        margin: 10px 0;
      `}
    }

    +li {
      margin-top: 30px;

      ${media.tablet`
        margin-top: 0;
      `}
    }

    .swiper-button-prev {
      pointer-events: auto;
    }

    .swiper-button-next {
      pointer-events: auto;
    }

    +.inactive {
      margin-top: 5px;
    }
  `}
`;

const Intro = styled.div`
  cursor: pointer;
  display: flex;
  flex: 0 0 100%;
  flex-wrap: nowrap;
  width: 100%;
`;

const Item = styled.div`
  flex: 0 0 25%;
  padding: 0;
  padding-right: 10px;

  ${media.tablet`
    flex: 0 0 calc(28.2% - 7px);
  `}

  &:nth-of-type(2n) {
    flex: 0 0 75%;

    ${media.tablet`
      flex: 0 0 36%;
    `}
  }

  &:nth-of-type(3n) {
    display: none;
    
    ${media.tablet`
      display: flex;
      flex: 0 0 37%;
    `}
  }
`;

const Meta = styled.div`
  margin-left: auto;
  order: 1;
  position: relative;
  width: 50%;

  ${media.tablet`
    flex: 0 0 27.8%;
    order: 0;
    padding: 0;
    padding-right: 20px;
    width: 27.8%;
  `}
`;

const Block = styled.div`
  flex-wrap: wrap;
  margin-top: 15px;
  padding-right: 30px;
  width: 100%;

  &:first-of-type {
    margin-top: 0;
  }

  ${media.tablet`
    padding-right: 80px;
  `}

  ${props => props.mobileHidden && css`
    display: none;
    
    ${media.tablet`
      display: flex;
    `}
  `}

  p {
    width: 100%;
  }

  +div {
    margin-top: 30px;
  }
`;

const Close = styled.div`
  cursor: pointer;
  bottom: -70px;
  height: 65px;
  left: 20%;
  padding: 5px;
  position: absolute;
  width: 65px;
  transform: rotate(0deg);
  transition: transform .3s ease-in-out;

  ${media.tablet`
    bottom: initial;
    height: 60px;
    left: -70px;
    top: -4px;
    width: 60px;
  `}

  &:hover {
    transform: rotate(90deg);
  }

  svg {
    stroke: white;
  }
`;

const ImageContainer = styled.div`
  position: relative;
  width: 100%;

  ${media.tablet`
    flex: 0 0 72.2%;
  `}

  .swiper-container {
    position: relative;
    width: 100%;
  }

  .swiper-wrapper {
    height: auto;
  }

  .swiper-slide {
    width: 100%;
  }

  .swiper-slide-active {

    video, picture {
      opacity: 1;
    }
  }

  video {
    background-color: ${theme.colors.black};
    height: 100%;
    opacity: 0;
    transition: opacity .4s ease-in-out;
    width: 100%;
  }

  picture {
    align-items: center;
    display: flex;
    justify-content: center;
    height: 0 !important;
    opacity: 0;
    padding-top: 100%;
    position: relative;
    transition: opacity .4s ease-in-out;
    width: 100% !important;

    ${media.tablet`
      padding-top: 56.25%;
    `}


    &.swiper-slide-active {
      opacity: 1;
    }

    img {
      object-fit: cover;
      position: absolute;
      height: 100%;
      left: 0;
      right: 0;
      top: 0;
      width: 100%;
    }
  }

  .swiper-container {
    overflow: visible;
  }

  .swiper-pagination {
    bottom: -82px;
    height: 25px;
    left: -40px;
    position: absolute;
    text-align: right;
    transform: rotate(-90deg);
    width: 100px;

    ${media.tablet`
      bottom: 35px;
      left: -80px;
      text-align: left;
    `}

    &.desktop-only {
      display: none;

      ${media.tablet`
        display: initial;
      `}
    }
  }

  .swiper-pagination-bullet {
    cursor: pointer;
    background-color: ${theme.colors.white};
    border: transparent;
    height: 15px;
    margin: 0 9px;
    opacity: .4;
    position: relative;
    width: 15px;
    z-index: 1;
    
    &.swiper-pagination-bullet-active {
      opacity: 1;
    }
  }
`;
