import React, { Component } from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

import media from '../common/mediaQuery'
import { Type } from '../common/type'

import Image from './image';
import VideoPlayer from './videoPlayer';

class ProjectBlock extends Component {
  
  render() {
    const { url, title, client, year, category, tags, video, mobileImage, desktopImage } = this.props;
    return ( 
      <ProjectWrapper id="featured">
        <Meta>
          <Block>
            <Link to={`/project/${url}`}>
              <Type bold>{ title } ↗</Type>
            </Link>
          </Block>
          <SecondaryContent>
            {client &&
              <Block>
                <Type>{ client }</Type>
                <Type>{ year }</Type>
              </Block>
            }
            {category && 
              <Block>
                <Type>{ category }</Type>
                {tags.map((tag) => (
                  <Type capitalized>+ { tag }</Type>
                ))}
              </Block>
            }
          </SecondaryContent>
        </Meta>
        {video ? 
          <ImageContainer>
            <Link to={`/project/${url}`}>
              <VideoPlayer isLooping videoStill={ desktopImage } videoLink={ video } />
            </Link>
          </ImageContainer>
        :
          <ImageContainer>
            <Link to={`/project/${url}`}>
              <Image mobileImage={ mobileImage } desktopImage={ desktopImage } />
            </Link>
          </ImageContainer>
        }
      </ProjectWrapper>
    );
  }
}
 
export default ProjectBlock;

const ProjectWrapper = styled.div`
  display: none;
  flex-wrap: wrap;
  margin-top: 50px;
  padding: 0 25px;
  width: 100%;

  ${media.tablet`
    display: flex;
    padding: 0 0 0 30px;
    margin-top: 35px;
  `}

  &:first-of-type {
    margin-top: 0;
  }
`;

const Meta = styled.div`
  align-items: flex-start;
  align-content: flex-start;
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  ${media.tabletLarge`
    flex: 0 0 27.1%;
    padding-right: 20px;
  `}
`;

const Block = styled.div`
  margin-top: 15px;
  width: 100%;

  ${media.tablet`
    margin-top: 40px;
    width: 50%;
  `}

  ${media.tabletLarge`
    margin-top: 30px;
    width: 100%;
  `}

  &:nth-of-type(2n), &:nth-of-type(3n) {
    
  }

  &:first-of-type {
    margin-top: 0;
  }
`;

const SecondaryContent = styled.div`
  display: none;
  width: 100%;

  ${media.tablet`
    display: block;
    flex: 0 0 50%;
  `}

  ${media.tabletLarge`
    flex: 0 0 100%;
    margin-top: 30px;
  `}

  ${Block} {
    ${media.tablet`
      width: 100%;
    `}
  }
`;

const ImageContainer = styled.div`
  margin-top: 30px;
  width: 100%;

  ${media.tabletLarge`
    flex: 0 0 72.9%;
    margin-top: 0;
  `}

  div {
    display: block;
    height: 0;
    margin-top: 30px;
    padding-top: 100%;
    position: relative;
    width: 100%;

    ${media.tabletLarge`
      margin-top: 0;
      padding-top: 56.25%;
    `}

    &.video {
      margin-top: 0;
    }

    video {
      background-color: #000000;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
`;
