import React, { Component } from 'react'
import styled, { css } from 'styled-components'
import media from '../common/mediaQuery'
import { Title, Type } from '../common/type'

class TextBlock extends Component {
  
  render() {
    const { full, title, copy, subcopy } = this.props;

    return ( 
      <TextWrapper id="info">
        <Inner full={full}>
          { title && <Type bold as="h2">{ title }</Type> }
          { copy && <Title as="p">{ copy }</Title> }
          { subcopy && <Type>{ subcopy }</Type> }
        </Inner>
      </TextWrapper>
    );
  }
}
 
export default TextBlock;

const TextWrapper = styled.div`
  display: flex;
  order: 0;
  padding: 0 25px;
  margin-top: 50px;
  width: 100%;

  ${media.tabletLarge`
    margin-top: 80px;
    order: initial;
  `}
`;

const Inner = styled.div`
  width: 100%;

  ${media.tabletLarge`
    flex: 0 0 72.1%;
    margin-left: auto;
  `}

  ${props => props.full && css`

    ${media.tablet`
      flex: 0 0 100%;
    `}
  `}
  
  ${Title} {
    margin-top: 10px;

    ${media.tablet`
      margin-top: 20px;
    `}

    ${media.tabletLarge`
      margin-top: 40px;
    `}
  }
`;
