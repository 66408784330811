import React, { Component } from 'react'
import styled from 'styled-components'
import media from '../common/mediaQuery'
import { Type } from '../common/type';

class ReferenceList extends Component {
  
  render() {
    const { title, list, secondaryTitle, secondaryList } = this.props;

    return (
      <ListWrapper>
        <Inner>
          <Block>
            <Type bold>{ title }</Type>
            <List>
              {list.map((listItem, index) => (
                listItem.spans.length
                ?
                  <li>
                    <a href={ listItem.spans[0].data.url } target="_blank">
                      <Type>{ listItem.text } ↗</Type>
                    </a>
                  </li>
                :
                  <li>
                    <Type>{ listItem.text }</Type>
                  </li>
              ))}
            </List>
          </Block>
          {secondaryTitle &&
            <Block>
              <Type bold>{ secondaryTitle }</Type>
              <List>
                {secondaryList.map((listItem, index) => (
                  listItem.spans.length
                  ?
                    <li>
                      <a href={ listItem.spans[0].data.url } target="_blank">
                        <Type>{ listItem.text } ↗</Type>
                      </a>
                    </li>
                  :
                    <li>
                      <Type>{ listItem.text }</Type>
                    </li>
                ))}
              </List>
            </Block>
          }
        </Inner>
      </ListWrapper>
    );
  }
}
 
export default ReferenceList;

const ListWrapper = styled.div`
  display: flex;
  margin-top: 50px;
  order: 2;
  padding: 0 25px;
  width: 100%;

  ${media.tablet`
    order: initial;
    padding: 0 30px;
  `}
`;

const Inner = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  ${media.tabletLarge`
    flex: 0 0 72%;
    margin-left: auto;
  `}
`;

const Block = styled.div`
  margin-top: 50px;
  width: 100%;

  ${media.tablet`
    flex: 0 0 50%;
    margin-right: auto;
    margin-top: 0;
    padding-right: 30px;
  `}

  &:first-of-type {
    margin-top: 0;
  }

  &:nth-of-type(2n) {

    ${media.tablet`
      padding-left: 2px;
      padding-right: 0;
    `}
  }
`;

const List = styled.ul`
  margin-top: 20px;
  width: 100%;

  ${media.tablet`
    flex: 0 0 50%;
    margin-top: 10px;
    padding-right: 30px;
  `}

  li {
    width: fit-content;
  }

  a {
    line-height: inherit;
  }
`;

