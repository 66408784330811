import React, { useRef, useEffect } from 'react';
import { Helmet } from "react-helmet"
import { graphql } from 'gatsby'
import styled, { css }from 'styled-components';
import { gsap } from 'gsap';

import ContextConsumer from '../components/context'

import { theme } from '../common/global';
import Layout from '../components/layout';
import media from '../common/mediaQuery';

import ProjectBlock from '../components/projectBlock';
import TextBlock from '../components/textBlock';
import ProjectList from '../components/ProjectList';
import ReferenceList from '../components/ReferenceList';
import Footer from '../components/footer';

// Sort and display the different slice options
const PostSlices = ({ slices }) => {
  return slices.map((slice, index) => {
    const res = (() => {

      switch(slice.type) {
        case 'project_block': return (
          <ProjectBlock tags={ slice.primary.featured_project._meta.tags } url={ slice.primary.featured_project._meta.uid } label={slice.primary.featured_project.label} title={slice.primary.featured_project.title} year={slice.primary.featured_project.year} client={slice.primary.featured_project.client} category={slice.primary.featured_project.category} video={slice.primary.featured_project.video_preview} mobileImage={slice.primary.featured_project.hero_image_mobile.url} desktopImage={slice.primary.featured_project.hero_image.url} />
        )
        case 'project_list': return (
          <ProjectList title={ slice.primary.block_title } projects={slice.fields} />
        )
        case 'text_block': return (
          <TextBlock title={ slice.primary.block_title } copy={ slice.primary.block_copy } subcopy={slice.primary.block_sub_copy} />
        )
        case 'reference_block': return (
          <ReferenceList title={ slice.primary.block_title } list={ slice.primary.block_list } secondaryTitle={ slice.primary.secondary_block_title } secondaryList={ slice.primary.secondary_block_list } />
        )
        default: return
      }
    })();
    return res;
  })
}

const IndexContent = ({ indexData }) => {
  return (
    <PostSlices slices={ indexData.body } />
  );
}

export default ({ data }) => {
  const headingRef = useRef(null);

  // Required check for no data being returned
  const doc = data.prismic.allHomepages.edges.slice(0,1).pop();
  if (!doc) return null;


  const animate = () => {
    if (!headingRef.current || typeof window === undefined) {
      return;
    }

    gsap.to(headingRef.current, {
      scrollTrigger: {
        once: true,
        autoKill: false,
        trigger: headingRef.current,
      },
      y: 0,
      autoAlpha: 1,
      duration: 0.75,
      stagger: 0.1,
      ease: 'power4.inOut',
    });
  };

  useEffect(() => {
    animate();
  }, []);

  return (

    <Layout>
      <Helmet>
        <title>Elkin Editions</title>
        <meta name="description" content="Elkin Editions is an independent video production studio based in Lower Manhattan." />
        <meta property="og:image" content="../../share-image.jpg"></meta>
      </Helmet>
      <ContextConsumer>
          {({ contextData }) => (
          <>
            <Wrapper isInverted={process.browser && localStorage.getItem('page_color')}>
              <Inner>
              <Hero>
                {/* LETTER E */}
                <svg className="letter letter--e" viewBox="0 0 378 393">
                  <path className="st0" d="M378.35,223.34H103.59c0.75,63.06,30.78,95.34,87.08,95.34c48.04,0,72.07-21.77,79.57-60.8l102.1,9.01
                  C350.57,350.96,287.52,393,190.67,393c-58.56,0-105.1-18.77-136.63-49.55C18.01,308.17,0,258.63,0,195.57
                  c0-60.06,16.51-111.1,53.3-146.38C86.33,16.9,131.37-0.36,189.92-0.36c59.3,0,105.85,18.77,137.38,52.55
                  C363.34,91.22,379.85,149.78,378.35,223.34z M123.11,100.23c-10.51,13.51-16.51,30.78-18.76,51.05h167.4
                  c-2.25-23.27-8.26-42.79-21.77-57.05c-14.26-15.01-33.03-21.77-60.05-21.77C159.14,72.45,137.38,81.46,123.11,100.23z"/>
                </svg>

                {/* LETTER LK */}
                <svg className="letter letter--lk" version="1.1" id="Layer_1" viewBox="0 0 490 525.5">
                  <path className="st0" d="M0.1,0h105.1v525.5H0.1V0z"/>
                  <path className="st0" d="M270.4,358.1l-32.3,31.5v135.9H133V0h105.1v272.5l116.4-122.4h132.9L340.9,290.5l149.4,235H370.2L270.4,358.1z" />
                </svg>


                {/* LETTER IN */}                    
                <svg className="letter " version="1.1" id="Layer_1" viewBox="0 0 482 533">
                  <path className="st0" d="M0.05,0h105.1v108.1H0.05V0z M0.05,157.65h105.1v375.35H0.05V157.65z"/>
                  <path className="st0" d="M482,303.28v229.71H376.15v-220.7c0-34.53-3.01-52.55-19.52-68.31c-13.51-12.76-26.28-15.02-48.79-15.02
                  c-32.28,0-69.81,21.77-69.81,99.09v204.94h-105.1V157.65h104.34v56.3c19.52-47.29,62.31-65.31,111.1-65.31
                  c48.05,0,75.82,11.26,99.84,35.28C478.25,213.95,482,254.48,482,303.28z"/>
                </svg>
              </Hero>
              <IndexContent indexData={ doc.node } />
              <Footer />
              </Inner>
              <Inner>
              <Hero loader ref={headingRef}>
                {/* LETTER E */}
                <svg className="letter letter--e" viewBox="0 0 378 393">
                  <path className="st0" d="M378.35,223.34H103.59c0.75,63.06,30.78,95.34,87.08,95.34c48.04,0,72.07-21.77,79.57-60.8l102.1,9.01
                  C350.57,350.96,287.52,393,190.67,393c-58.56,0-105.1-18.77-136.63-49.55C18.01,308.17,0,258.63,0,195.57
                  c0-60.06,16.51-111.1,53.3-146.38C86.33,16.9,131.37-0.36,189.92-0.36c59.3,0,105.85,18.77,137.38,52.55
                  C363.34,91.22,379.85,149.78,378.35,223.34z M123.11,100.23c-10.51,13.51-16.51,30.78-18.76,51.05h167.4
                  c-2.25-23.27-8.26-42.79-21.77-57.05c-14.26-15.01-33.03-21.77-60.05-21.77C159.14,72.45,137.38,81.46,123.11,100.23z"/>
                </svg>

                {/* LETTER LK */}
                <svg className="letter letter--lk" viewBox="0 0 490 525">
                  <path className="st0" d="M0.11-0.49h105.1v525.49H0.11V-0.49z"/>
                  <path className="st0" d="M270.35,357.59l-32.28,31.53v135.87h-105.1V-0.49h105.1v272.5l116.35-122.36H487.3L340.92,290.02l149.39,234.97
                  H370.2L270.35,357.59z"/>
                </svg>

                {/* LETTER IN */}                    
                <svg className="letter letter-in" viewBox="0 0 482 533">
                  <path className="st0" d="M0.05,0h105.1v108.1H0.05V0z M0.05,157.65h105.1v375.35H0.05V157.65z"/>
                  <path className="st0" d="M482,303.28v229.71H376.15v-220.7c0-34.53-3.01-52.55-19.52-68.31c-13.51-12.76-26.28-15.02-48.79-15.02
                  c-32.28,0-69.81,21.77-69.81,99.09v204.94h-105.1V157.65h104.34v56.3c19.52-47.29,62.31-65.31,111.1-65.31
                  c48.05,0,75.82,11.26,99.84,35.28C478.25,213.95,482,254.48,482,303.28z"/>
                </svg>
              </Hero>
              <IndexContent indexData={ doc.node } />
              </Inner>
            </Wrapper>
          </>
        )}
      </ContextConsumer>
    </Layout>
  );
}

const Wrapper = styled.div`
  background-color: ${theme.colors.black};
  transition: all ${theme.speed.fast} ease-in-out;
  width: 100%;

  ${props => props.isInverted === 'true' && css`
    background-color: ${theme.colors.white};
    color: ${theme.colors.black};
    background-color: ${theme.colors.white};
    .letter {

      path {
        fill: ${theme.colors.black};
      }
    }

    a {
      color: ${theme.colors.black};
    }

    svg {
      stroke: ${theme.colors.black};
    }

    .swiper-pagination-bullet {
      background-color: ${theme.colors.black};
    }
  `}
`;

const Inner = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
`;

const Hero = styled.div`
  align-items: flex-end;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  height: calc(100vh - 85px);
  padding: 0 25px;
  position: relative;
  transition: all ${theme.speed.fast} ease-in-out;
  width: 100%;

  ${media.tabletLarge`
    height: calc(100vh - 90px);
    padding: 0 30px;
  `}

  ${props => props.loader && css`
    opacity: 0;
    transform: translateY(200px);

    svg {
      align-items: flex-end;
      display: flex;
      height: auto;
      width: 100%;

      &.letter {

        &--e {
          flex: 0 0 100%;
        }

        ${media.tablet`
          /* height: 400px; */

          &--e {
            flex: 0 0 26%;
            padding-right: 15px;
          }

          &--lk {
            flex: 0 0 37.29%;
          }

          &--in {
            flex: 0 0 32%;

          }

          &--l {
            flex: 0 0 10.2%;

          }

          &--k {
            flex: 0 0 27%;
          }

          &--i {
            flex: 0 0 10.2%;
            padding-right: 37px;
          }

          &--n {
            flex: 0 0 26%;
          }
        `}
        }

        path {
          fill: ${theme.colors.white};
        }
      }
    }
  `}

  svg {
    align-items: flex-end;
    display: flex;
    height: auto;
    width: 100%;

    &.letter {

      &--e {
        flex: 0 0 100%;
      }

      ${media.tablet`
        /* height: 400px; */

        &--e {
          flex: 0 0 27.8%;
          padding-right: 15px;
        }

        &--lk {
          flex: 0 0 36.36%;
        }

        &--in {
          flex: 0 0 31%;
        }
      `}
    }

    path {
      fill: ${theme.colors.white};
    }
  }
`;

export const query = graphql`
{
  prismic {
    allHomepages {
      edges {
        node {
          body {
            ... on PRISMIC_HomepageBodyProject_block {
              type
              label
              primary {
                featured_project {
                  ... on PRISMIC_Project {
                    label
                    title
                    _meta {
                      uid
                      tags
                    }
                    client
                    category
                    year
                    video_preview
                    hero_image
                    hero_image_mobile
                  }
                }
              }
            }
            ... on PRISMIC_HomepageBodyText_block {
              type
              label
              primary {
                block_copy
                block_sub_copy
                block_title
              }
            }
            ... on PRISMIC_HomepageBodyProject_list {
              type
              label
              primary {
                block_title
              }
              fields {
                project {
                  ... on PRISMIC_Project {
                    _meta {
                      uid
                      tags
                    }
                    title
                    project_copy
                    label
                    category
                    client
                    year
                    video_link
                    hero_image
                    hero_image_mobile
                    body {
                      ... on PRISMIC_ProjectBodyImage_gallery {
                        type
                        label
                        fields {
                          is_video
                          mobile_image
                          desktop_image
                          video_still
                          slide_video_link
                        }
                      }
                      ... on PRISMIC_ProjectBodyVideo {
                        type
                        label
                        primary {
                          placeholder
                          video_link
                        }
                      }
                    }
                  }
                }
              }
            }
            ... on PRISMIC_HomepageBodyReference_block {
              type
              primary {
                block_list
                block_title
                secondary_block_title
                secondary_block_list
              }
            }
          }
        }
      }
    }
  }
}
`