import React, { Component } from 'react'
import styled from 'styled-components'

import media from '../common/mediaQuery'

class Image extends Component {
  render() {
    const { mobileImage, desktopImage } = this.props

    return (
      <ImageWrapper>
        <source media="(min-width: 768px)" srcSet={desktopImage} />
        <source srcSet={mobileImage} />
        <img alt="" src={mobileImage} />
      </ImageWrapper> 
    );
  }
}

export default Image

const ImageWrapper = styled.picture`
  display: block;
  height: 0;
  overflow: hidden;
  position: relative;
  padding-top: 100%;
  width: 100%;

  ${media.tablet`
    padding-top: 56.25%;
  `}

  img {
    bottom: 0;
    height: 100%;
    left: 0;
    object-fit: cover;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
  }
`;
