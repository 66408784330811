import React, { Component } from 'react'
import styled, { css } from 'styled-components'

import media from '../common/mediaQuery'
import { Type } from '../common/type'

import ContextConsumer from './context'
import FullProject from './fullProject';


class ProjectList extends Component {
  
  render() {
    const { title, projects } = this.props;
    
    const PostSlices = ({ slices }) => {
      return slices.map((slice, index) => {
        const res = (() => {
          return  <FullProject key={ index } url={ slice.project._meta.uid } tags={ slice.project._meta.tags } label={slice.project.label} title={slice.project.title} copy={slice.project.project_copy} year={slice.project.year} client={slice.project.client} category={slice.project.category} gallery={slice.project.body} />
        })();
        return res;
      })
    }

    return ( 
      <ContextConsumer>
        {({ contextData }) => (
          <ListWrapper id="index" className="full-list" active={contextData.projectActive}>
            { title && <Type className="title" bold as="h2">{ title }<Type className="title">(tap to expand)</Type></Type> }
            <PostSlices slices={ projects }/>
          </ListWrapper>
        )}
      </ContextConsumer>
    );
  }
}
 
export default ProjectList;

const ListWrapper = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin-top: 50px;
  order: 1;
  padding: 0 25px;
  width: 100%;

  ${media.tablet`
    margin-top: 80px;
    order: initial;
  `}

   .title {
    display: flex;
    margin-bottom: 10px;
    width: 100%;

    ${media.tablet`
      display: none;
    `}

    p {
      margin-bottom: 0;
      margin-left: 5px;
      width: auto;
    }
  }

  li {
    opacity: 1;
    transition: opacity .4s ease-in-out;
  }

  ${props => props.active && css`

    li {
      opacity: 0.3;

      ${props => props.open && css`
        opacity: 1;
      `}
    }
  `}
`;
