import React, { Component } from 'react'
import styled, { css } from 'styled-components'
import { theme } from '../common/global'

class VideoPlayer extends Component {
  state = {
    hidden: false
  }

  playVideo = () => {
    this.refs.vidRef.play();
    this.setState(prevState => ({
      hidden: !prevState.hidden
    }));
  };
  
  render() {
    const { videoStill, videoLink, isCarouselItem, isLooping } = this.props

    return (
      <VideoWrapper className="video" swiper={isCarouselItem}>
        <video ref="vidRef" width="100%" poster={ videoStill } autoPlay={ isLooping } muted={ isLooping } loop={ isLooping } playsInline controls={this.state.hidden} disablePictureInPicture controlsList="nodownload">
          <source src={ videoLink } type="video/mp4"/>
          Your browser does not support the video tag.
        </video>
        {!isLooping &&
          <Overlay onClick={this.playVideo} hidden={ this.state.hidden }>
            <svg viewBox="0 0 115 133">
              <polygon points="114.58,66 -0.42,-0.4 -0.42,132.4 "/>
            </svg>
          </Overlay>
        }
      </VideoWrapper> 
    );
  }
}

export default VideoPlayer;

const VideoWrapper = styled.div`
  max-height: 100vh;
  position: relative;
  width: 100%;

  ${props => props.swiper && css`
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  `}

  video {
    background-size: cover; 
    display: block;
    height: 100%;
    width: 100%;
  }
`;

const Overlay = styled.div`
  align-items: center;
  background-color: rgba(0, 0, 0, .35);
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: ${theme.index.z1};

  ${props => props.hidden && css`
    display: none;
  `}

  svg {
    cursor: pointer;
    width: 80px;
  }

  polygon {
    fill: #FFE600;
  }
`;
